<!--
 * @Description: Empty
 * @Author: Anita
 * @Date: 2022-05-18 20:10:24
 * @LastEditTime: 2022-05-18 20:10:29
 * @LastEditors: Anita
 * @Since:
-->
<template>
  <div />
</template>

<script>
export default {
  name: 'Empty',
  created() {
    this.$router.replace(this.$route.query.router)
  }
}
</script>

<style lang="scss" scoped></style>
